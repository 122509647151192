import { FirmwareVersion } from "./firmware-version";
import { ReleaseChannel } from "./release-channel";
import { ReleaseVersion } from "./release-version";

export class Product {
    productId: string = '';

    name: string = '';
    description: string = '';
    hardware: string = '';
    operatingSystem: string = '';
    image: any;
    isLegacy: boolean = false;
    count: number = 0;

    releases: ReleaseVersion[] = [];
    versions: FirmwareVersion[] = [];
    channels: ReleaseChannel[] = [];

    constructor(init?: any) {
        Object.assign(this, init);
    }

    get totalDeviceCount(): number {
        return this.count;
    }

    getVersionForChannel(channelName: string): ReleaseVersion | undefined {
        const releasesOnVersion = this.releases.filter(r => r.channel === channelName).sort(ReleaseVersion.compareByVersion)
        return releasesOnVersion.pop();
    }

    getChannelFromName(channelName: string): ReleaseChannel | undefined {
        return this.channels.find(c => c.name === channelName);
    }
}