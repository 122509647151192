import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ReleaseChannel } from 'src/app/models/release-channel';

@Component({
  selector: 'app-from-to-channel',
  templateUrl: './from-to-channel.component.html',
  styleUrls: ['./from-to-channel.component.scss']
})
export class FromToChannelComponent implements OnInit {
  @Input() channels: ReleaseChannel[] = [];
  @Output() fromTo: EventEmitter<{ from: ReleaseChannel[], to: ReleaseChannel }> = new EventEmitter<{ from: ReleaseChannel[], to: ReleaseChannel }>();
  from: ReleaseChannel[] = [];
  to: ReleaseChannel | null = null;

  constructor() { }

  ngOnInit(): void {
  }

  fromSelected(value: ReleaseChannel) {
    if (this.from.includes(value)) {
      this.from = this.from.filter(f => f !== value)
    } else {
      this.from.push(value);
    }
  }

  toSelected(value: ReleaseChannel) {
    this.to = value;
  }

  get allowSubmit(): boolean {
    return !!this.from && !!this.to;
  }

  submit(): void {
    if (!!this.from && !!this.to) {
      this.fromTo.emit({
        from: this.from,
        to: this.to
      })
    }
  }

}
