<div class="row">
    <div class="col-7">
        <div class="row pe-5 border-end border-dashed">
            <div class="col bg-light pt-2 mb-2 ps-4">
                <h5 class="text-uppercase">Filters</h5>
                <div *ngFor="let channelFilter of channelFilters">
                    <input class="form-check-input me-2" type="checkbox" value=""
                        id="{{channelFilter.channel.name}}FilterCheck" [(ngModel)]="channelFilter.selected"
                        [disabled]="disableFilter(channelFilter.channel.name)" />
                    <label class="form-check-label text-uppercase" for="{{channelFilter.channel.name}}FilterCheck">
                        {{channelFilter.channel.name}}
                    </label>
                </div>
                <div class="pb-2">
                    <input class="form-check-input me-2" type="checkbox" value="" id="unassignedFilterCheck"
                        [(ngModel)]="unassignedFilter" [disabled]="disableFilter('')" />
                    <label class="form-check-label text-uppercase" for="unassignedFilterCheck">
                        Unassigned
                    </label>
                </div>
            </div>
            <div class="col bg-light ps-0 pb-2 pt-2 mb-2">
                <h5 class="ps-2 ms-1 text-uppercase text-center">Release History</h5>

                <div class="border-start ps-4">
                    <div class="list-group">
                        <button *ngFor="let version of versions" type="button"
                            class="list-group-item list-group-item-action border-0 text-center py-0"
                            [class.active]="selectedRelease?.version === version.version"
                            (click)="versionPicked(version)">{{version.version}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-5">
        <div class="ps-5" *ngIf="canPublish">
            <div class="d-flex flex-row justify-content-between flex-wrap"
                *ngIf="!!selectedRelease?.otaPath && downloadPermission">
                <div>{{ selectedRelease?.otaPath }}</div>
                <div><button class="text-uppercase btn btn-light" (click)="downloadClicked()"
                        [class.disabled]="downloading"><i class="fa fa-cloud-download me-1" aria-hidden="true"
                            *ngIf="!downloading"></i>
                        <div class="spinner-border spinner-border-sm" role="status" *ngIf="downloading">
                            <span class="visually-hidden">Loading...</span>
                        </div>Download
                    </button></div>
            </div>
            <div *ngIf="changeReleasePermission">
                <div class="pt-4">
                    <h5 class="text-uppercase">Assign to Channel</h5>
                </div>
                <div>
                    <div class="dropdown">
                        <button class="btn dropdown-toggle text-uppercase border text-start d-flex w-100" type="button"
                            id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            <span class="me-auto channel-selector-text text-left">{{channelSelectorText}}</span>
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li><a class="dropdown-item text-uppercase" (click)="channelPicked(channel)"
                                    *ngFor="let channel of channels">{{channel.name}}</a></li>
                        </ul>
                    </div>
                </div>
                <div class="float-end">
                    <button class="btn m-2" (click)="cancelClicked()">Cancel</button>
                    <button class="btn btn-primary mt-2" [class.disabled]="publishDisabled || publishing"
                        (click)="publish()">
                        <div class="spinner-border spinner-border-sm" role="status" *ngIf="publishing">
                            <span class="visually-hidden">Loading...</span>
                        </div>Publish
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>