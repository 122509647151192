export class ReleaseVersion {
    productId: string | undefined;
    version: string | undefined;
    channel: string | undefined;
    otaPath: string | null | undefined;

    constructor(init?: any) {
        Object.assign(this, init);
    }

    public static compareByVersion(rv1: ReleaseVersion, rv2: ReleaseVersion): number {
        const s1 = (rv1.version?.split('.') || []).map(n => +n);
        const s2 = (rv2.version?.split('.') || []).map(n => +n);
        while(true) {
            const section1 = s1.shift();
            const section2 = s2.shift();
            if(section1 === undefined) {
                if (section2 === undefined) {
                    return 0;
                } else {
                    return -1;
                }
            } else {
                if (section2 === undefined) {
                    return 1;
                }
            }
            if (section1 > section2) {
                return 1;
            }
            if (section2 > section1) {
                return -1;
            }
        }
    }
}