import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { ReleaseChannel } from 'src/app/models/release-channel';
import { PermissionsService } from 'src/app/services/permissions.service';
import { ProductsService } from 'src/app/services/products.service';
import { ToastService, ToastType } from 'src/app/services/toast.service';

@Component({
  selector: 'app-all-channels-page',
  templateUrl: './all-channels-page.component.html',
  styleUrls: ['./all-channels-page.component.scss']
})
export class AllChannelsPageComponent implements OnInit {
  channels: ReleaseChannel[] = [];
  productId: string = '';

  constructor(
    private readonly productsService: ProductsService,
    private readonly route: ActivatedRoute,
    private readonly toastService: ToastService,
    private readonly permissionsService: PermissionsService,
    private readonly router: Router
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((params: any) => {
      this.productId = params.productId;
      this.productsService.getReleaseChannels(this.productId).subscribe(channels => {
        this.channels = channels;
      });
    });
    this.permissionsService.isElevated().subscribe(isElevated => {
      if (!isElevated) {
        this.router.navigate(['not-allowed']);
      }
    });
  }

  rechannel(fromTo: { from: ReleaseChannel[], to: ReleaseChannel }): void {
    forkJoin(fromTo.from.map(channel => {
      return this.productsService.rechannelAllDevicesOnChannel(this.productId, channel.name, fromTo.to.name)
    })).subscribe(_ => {
      this.toastService.notify('Device rechannel has been scheduled.', ToastType.SUCCESS);
    },
      error => {
        this.toastService.notify('Device rechannel has failed.', ToastType.FAILURE);
      })
  }
}
