import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { AllChannelsPageComponent } from './application-components/all-channels-page/all-channels-page.component';
import { ChannelPageComponent } from './application-components/channel-page/channel-page.component';
import { HomePageComponent } from './application-components/home-page/home-page.component';
import { LogoutFinishedComponent } from './application-components/logout-finished/logout-finished.component';
import { NotAllowedPageComponent } from './application-components/not-allowed-page/not-allowed-page.component';
import { NotFoundPageComponent } from './application-components/not-found-page/not-found-page.component';
import { ProductViewPageComponent } from './application-components/product-view-page/product-view-page.component';
import { ProductsPageComponent } from './application-components/products-page/products-page.component';
import { ReleasesPageComponent } from './application-components/releases-page/releases-page.component';
import { SignoutComponent } from './application-components/signout/signout.component';
import { UserRoleGuardService } from './security-components/user-role-guard.service';

const routes: Routes = [
    {
        path: 'products',
        canActivate: [MsalGuard, UserRoleGuardService],
        data: {
            breadcrumb: 'Products',
        },
        children: [
            {
                path: '',
                data: {
                    breadcrumb: null
                },
                component: ProductsPageComponent
            },
            {
                path: ':productId',
                data: {
                    breadcrumb: 'Product',
                },
                children: [
                    {
                        path: '',
                        data: {
                            breadcrumb: null,
                        },
                        component: ProductViewPageComponent
                    },
                    {
                        path: 'all',
                        data: {
                            breadcrumb: 'All Channels',
                        },
                        component: AllChannelsPageComponent
                    },
                    {
                        path: 'releases',
                        data: {
                            breadcrumb: 'All Releases',
                        },
                        component: ReleasesPageComponent
                    },
                    {
                        path: ':channel',
                        data: {
                            breadcrumb: 'Release Channel',
                        },
                        component: ChannelPageComponent
                    }
                ]
            }
        ]
    },
    {
        path: 'not-allowed',
        component: NotAllowedPageComponent
    },
    {
        path: 'logout-finished',
        component: LogoutFinishedComponent
    },
    {
        path: 'signout',
        component: SignoutComponent
    },
    {
        path: '',
        canActivate: [MsalGuard],
        component: HomePageComponent
    },
    {
        path: '**', 
        component: NotFoundPageComponent
    }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
      enableTracing: false
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
