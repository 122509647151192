<div class="bg-light p-3">
    <h5 class="text-uppercase text-center">Current Releases</h5>
    <div *ngFor="let channel of sortedChannels" class="row">
        <div class="col text-end fw-bold product-label text-uppercase px-1">{{channel.name}}:</div>
        <div class="col text-start px-0">{{product.getVersionForChannel(channel.name)?.version || 'N/A'}}</div>
    </div>
    <div class="row">
        <div class="col text-end fw-bold product-label text-uppercase px-1">Unassigned:</div>
        <div class="col text-start px-0">{{unassignedVersion}}</div>
    </div>
    <div class="d-flex flex-row justify-content-center pt-2">
        <button class="btn btn-primary text-uppercase my-auto" routerLink="releases">Release History</button>
    </div>
</div>