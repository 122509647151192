import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PermissionsService } from 'src/app/services/permissions.service';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {

  constructor(private readonly router: Router,
    private readonly permissionsService: PermissionsService) { }

  ngOnInit(): void {
    this.permissionsService.isUser().subscribe(allowed => {
      if (allowed) {
        this.router.navigate(['products'])

      } else {
        this.router.navigate(['not-allowed'])
      }
    })
  }

}
