import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Toast } from 'bootstrap';
import { filter } from 'rxjs/operators';
import { ToastService, ToastType } from 'src/app/services/toast.service';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent implements OnInit {
  @ViewChild('successToast', { static: true }) toastEl!: ElementRef<HTMLDivElement>;
  toast: Toast | null = null;
  message: string = '';
  type: ToastType = ToastType.STANDARD;

  constructor(private readonly toastService: ToastService) {}

  ngOnInit() {
    this.toast = new Toast(this.toastEl.nativeElement, {});
    this.toastService.getToastFeed().pipe(
      filter(message => !!message && !!message.message)
    ).subscribe(data => {
      this.message = data.message;
      this.type = data.type;
      this.show();
    });
  }

  show() {
    this.toast!.show();
    setTimeout(() => this.toast!.hide(), 5000)
  }

  get isSuccess(): boolean {
    return this.type === ToastType.SUCCESS;
  }

  get isFailure(): boolean {
    return this.type === ToastType.FAILURE;
  }

}
