import { Component, Input, OnInit } from '@angular/core';
import { Product } from 'src/app/models/product';
import { ReleaseChannel } from 'src/app/models/release-channel';

@Component({
  selector: 'app-release-distribution-line',
  templateUrl: './release-distribution-line.component.html',
  styleUrls: ['./release-distribution-line.component.scss']
})
export class ReleaseDistributionLineComponent implements OnInit {
  @Input() product: Product = new Product();
  @Input() channels: ReleaseChannel[] = [];
  @Input() readOnly: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  allProductsCount(): number {
    return this.channels.map(c => c.count).reduce((a, b) => a + b, 0);
  }

  get sortedChannels(): ReleaseChannel[] {
    return this.channels.sort((a, b) => a.ordinal - b.ordinal)
  }

}
