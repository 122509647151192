<div class="row">
    <div class="col bg-light py-2 px-3 m-3">
        <h5 class="text-uppercase">Devices on Channels</h5>
        <div class="form-check" *ngFor="let channel of channels">
            <input type="checkbox" class="form-check-input" name="fromRadio" value="{{channel.name}}FromRadio"
                (click)="fromSelected(channel)" />
            <label for="{{channel.name}}FromRadio" class="form-check-label text-uppercase">{{channel.name}}</label>
        </div>
    </div>
    <div class="col py-2 px-3 m-3">
        <h5 class="text-uppercase">Assign Devices to Channel</h5>
        <div class="form-check" *ngFor="let channel of channels">
            <input type="radio" class="form-check-input" name="toRadio" value="{{channel.name}}ToRadio"
                (click)="toSelected(channel)" />
            <label for="{{channel.name}}ToRadio" class="form-check-label text-uppercase">{{channel.name}}</label>
        </div>
    </div>
</div>
<div class="pt-2">
    <button class="btn btn-primary text-uppercase" [class.disabled]="!allowSubmit" (click)="submit()">Move</button>
</div>