import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Product } from 'src/app/models/product';
import { ReleaseChannel } from 'src/app/models/release-channel';
import { PermissionsService } from 'src/app/services/permissions.service';
import { ProductsService } from 'src/app/services/products.service';

@Component({
  selector: 'app-product-view-page',
  templateUrl: './product-view-page.component.html',
  styleUrls: ['./product-view-page.component.scss']
})
export class ProductViewPageComponent implements OnInit {
  product: Product = new Product();
  channels: ReleaseChannel[] = [];
  isBasicUser: boolean = true;

  constructor(
    private readonly productsService: ProductsService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly permissionService: PermissionsService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((params: any) => {
      this.productsService.getCompleteProduct(params.productId).subscribe(product => {
        this.product = product;
      },
      error => {
        this.router.navigate(['product-not-found']);
      }
      );
      this.productsService.getReleaseChannelsWithCount(params.productId).subscribe(channels => {
        this.channels = channels;
      })
    });

    this.permissionService.isElevated().subscribe(isElevated => this.isBasicUser = !isElevated);
  }

}
