import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Product } from 'src/app/models/product';
import { ReleaseChannel } from 'src/app/models/release-channel';
import { ReleaseVersion } from 'src/app/models/release-version';
import { PermissionsService } from 'src/app/services/permissions.service';
import { ProductsService } from 'src/app/services/products.service';
import { ToastService, ToastType } from 'src/app/services/toast.service';

@Component({
  selector: 'app-releases-page',
  templateUrl: './releases-page.component.html',
  styleUrls: ['./releases-page.component.scss'],
})
export class ReleasesPageComponent implements OnInit {
  product: Product = new Product();
  channels: ReleaseChannel[] = [];
  releases: ReleaseVersion[] = [];
  downloading = false;
  publishing = false;
  downloadPermission = false;
  changeReleasePermission = false;

  constructor(
    private readonly productsService: ProductsService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly permissionsService: PermissionsService,
    private readonly toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params: any) => {
      this.productsService
        .getCompleteProduct(params.productId)
        .subscribe((product) => {
          this.product = product;
        });
      this.productsService
        .getReleaseChannels(params.productId)
        .subscribe((channels) => {
          const channelsWithUnassigned = [
            ...channels,
            <ReleaseChannel>{
              name: 'Unassigned',
              ordinal: 5,
              detail: '',
              instance: '',
            },
          ].sort((a, b) => a.ordinal - b.ordinal);
          this.channels = channelsWithUnassigned;
        });
      this.productsService
        .getReleases(params.productId)
        .subscribe((releases) => {
          this.releases = releases;
        });
    });
    this.permissionsService
      .isElevated()
      .subscribe((allowed) => (this.downloadPermission = allowed));
    this.permissionsService
      .isAdmin()
      .subscribe((allowed) => (this.changeReleasePermission = allowed));
  }

  returnToProduct(): void {
    this.router.navigate(['products', this.product.productId]);
  }

  publish(publishRequest: {
    release: ReleaseVersion;
    channel: ReleaseChannel;
  }): void {
    this.publishing = true;
    this.productsService
      .publishRelease(
        this.product.productId,
        publishRequest.channel.name,
        publishRequest.release.version || ''
      )
      .subscribe(
        (_) => {
          this.toastService.notify(
            `Publishing ${publishRequest.release.version} to ${publishRequest.channel.name} is in progress.`,
            ToastType.SUCCESS
          );
          this.publishing = false;
          this.productsService
            .getReleases(this.product.productId)
            .subscribe((releases) => {
              this.releases = releases;
            });
        },
        (_error) => {
          this.toastService.notify(
            `Publishing ${publishRequest.release.version} to ${publishRequest.channel.name} failed.`,
            ToastType.FAILURE
          );
          this.publishing = false;
        }
      );
  }

  downloadClicked(version: string) {
    this.downloading = true;
    this.productsService
      .downloadRelease(this.product.productId, version)
      .subscribe((_) => (this.downloading = false));
  }
}
