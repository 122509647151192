import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { intersection } from 'lodash';
import { FirmwareVersion } from 'src/app/models/firmware-version';
import { Product } from 'src/app/models/product';
import { ReleaseChannel } from 'src/app/models/release-channel';
import { ReleaseVersion } from 'src/app/models/release-version';

@Component({
  selector: 'app-release-assignment',
  templateUrl: './release-assignment.component.html',
  styleUrls: ['./release-assignment.component.scss'],
})
export class ReleaseAssignmentComponent implements OnInit, OnChanges {
  @Input() product: Product = new Product();
  @Input() channels: ReleaseChannel[] = [];
  @Input() releases: ReleaseVersion[] = [];
  @Input() downloading: boolean = false;
  @Input() publishing: boolean = false;
  @Input() changeReleasePermission = false;
  @Input() downloadPermission = false;
  @Output() publishRelease: EventEmitter<{
    release: ReleaseVersion;
    channel: ReleaseChannel;
  }> = new EventEmitter<{ release: ReleaseVersion; channel: ReleaseChannel }>();
  @Output() cancel: EventEmitter<undefined> = new EventEmitter<undefined>();
  @Output() download: EventEmitter<string> = new EventEmitter<string>();
  static readonly unassignedChannel = <ReleaseChannel>{
    name: 'Unassigned',
    ordinal: 5,
    detail: '',
    instance: '',
  };
  selectedRelease: ReleaseVersion | null = null;
  selectedChannel: ReleaseChannel = ReleaseAssignmentComponent.unassignedChannel;
  channelFilters: { channel: ReleaseChannel; selected: boolean }[] = [];
  unassignedFilter: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(arg: any): void {
    console.log('change triggered');
    console.log(arg);
    this.channelFilters = this.channels
      .filter((channel) => channel.name !== 'Unassigned')
      .map((c) => ({
        channel: c,
        selected: false,
      }));
    if (!!arg['releases']) {
      this.selectedChannel = ReleaseAssignmentComponent.unassignedChannel;
      this.selectedRelease = null;
    }
  }

  versionPicked(version: ReleaseVersion) {
    this.selectedRelease = version;
    this.selectedChannel =
      this.channels.find((c) => version.channel === c.name) || ReleaseAssignmentComponent.unassignedChannel;
  }

  channelPicked(channel: ReleaseChannel) {
    this.selectedChannel = channel;
  }

  get channelSelectorText(): string {
    return this.selectedChannel.name;
  }

  get publishDisabled(): boolean {
    return !this.selectedChannel || !this.selectedRelease;
  }

  publish(): void {
    if (this.selectedRelease !== null && this.selectedChannel !== null) {
      if (this.selectedChannel.name === ReleaseAssignmentComponent.unassignedChannel.name) {
        this.publishRelease.emit({
          release: this.selectedRelease,
          channel: <ReleaseChannel>{
            name: '',
            ordinal: 5,
            detail: '',
            instance: '',
          },
        });
      } else {
        this.publishRelease.emit({
          channel: this.selectedChannel,
          release: this.selectedRelease,
        });
      }
    } else {
      console.warn('User has been allowed to request an invalid release');
    }
  }

  cancelClicked(): void {
    this.selectedRelease = null;
    // this.cancel.emit();
  }

  get versions(): ReleaseVersion[] {
    let activeFilters = this.channelFilters
      .filter((cf) => cf.selected)
      .map((cf) => cf.channel.name);
    if (this.unassignedFilter) {
      activeFilters.push('');
    }
    if (activeFilters.length === 0) {
      return this.releases.sort((a, b) =>
        ReleaseVersion.compareByVersion(b, a)
      );
    }
    return this.releases
      .filter((version) => activeFilters.includes(version?.channel || ''))
      .sort((a, b) => ReleaseVersion.compareByVersion(b, a));
  }

  disableFilter(filter: string): boolean {
    if (
      this.channelFilters.filter((cf) => cf.selected).length === 0 &&
      !this.unassignedFilter
    ) {
      return false;
    }
    if (
      this.channelFilters.filter(
        (cf) => cf.selected && cf.channel.name === filter
      ).length > 0
    ) {
      return false;
    }
    if (this.unassignedFilter && filter === '') {
      return false;
    }
    return true;
  }

  downloadClicked(): void {
    this.download.emit(this.selectedRelease?.version);
  }

  get canPublish(): boolean {
    return !!this.selectedRelease;
  }
}
