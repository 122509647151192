<div class="d-flex pb-3">
    <input class="form-control" placeholder="Filter" [ngModel]="''" (ngModelChange)="filter($event)"  />
    <div class="dropdown ms-2">
        <button class="btn btn-primary dropdown-toggle text-uppercase" [class.disabled]="!anySelected()" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
          Move To
        </button>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
          <li *ngFor="let channel of availableChannels"><a class="dropdown-item" (click)="moveToChannel(channel)">{{channel.name}}</a></li>
        </ul>
      </div>
</div>
<table class="table">
    <thead>
        <th scope="col"><input class="form-check-input mx-2 all-check" type="checkbox" (change)="allChecked($event)" /> All</th>
        <th scope="col">MAC Address</th>
        <th scope="col">Release</th>
        <th scope="col">Brand</th>
        <th scope="col">Time Zone</th>
    </thead>
    <tbody>
        <tr *ngFor="let selectableDevice of selectableDevices">
            <td><input class="form-check-input" type="checkbox" [(ngModel)]="selectableDevice.checked" /></td>
            <td>{{selectableDevice.device.macAddress}}</td>
            <td>{{selectableDevice.device.bundleVersion}}</td>
            <td>{{selectableDevice.device.brand}}</td>
            <td>{{selectableDevice.device.timeZone}}</td>
        </tr>
    </tbody>
</table>