import { Injectable } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AccountInfo, InteractionStatus } from '@azure/msal-browser';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

interface Account extends AccountInfo {
  idTokenClaims?: {
    roles?: string[]
  }
}

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {

  constructor(
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
  ) { }

  isElevated(): Observable<boolean> {
    return this.getRoles().pipe(
      map(roles => {
        return roles.includes('ElevatedUser') || roles.includes('Administrator')
      })
    )
  }

  isAdmin(): Observable<boolean> {
    return this.getRoles().pipe(
      map(roles => {
        return roles.includes('Administrator')
      })
    )
  }

  isUser(): Observable<boolean> {
    return this.getRoles().pipe(
      map(roles => {
        return roles.includes('ElevatedUser') || roles.includes('Administrator') || roles.includes('User')
      })
    )
  }

  getRoles(): Observable<string[]> {
    return this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        map(_ => {
          let account: Account | null = this.authService.instance.getAllAccounts()[0];
          if (!account) {
            account = this.authService.instance.getActiveAccount();
          }

          return account?.idTokenClaims?.roles || [];
        })
      )
  }
}
