export interface DevicesFragment {
    entities: Device[],
    continuationToken: string
  }

export class Device {
    accountId: string = '';
    deviceId: string = '';
    bundleVersion: string | undefined;
    macAddress: string | undefined;
    brand: string | undefined;
    timeZone: string | undefined;

    constructor(init?: Partial<Device>) {
        Object.assign(this, init);
    }
}