import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { AccountInfo } from '@azure/msal-common';
import { Observable } from 'rxjs';
import { PermissionsService } from '../services/permissions.service';

interface Account extends AccountInfo {
  idTokenClaims?: {
    roles?: string[]
  }
}

@Injectable({
  providedIn: 'root'
})
export class UserRoleGuardService implements CanActivate {

  constructor(
    private router: Router,
    private readonly permissionsService: PermissionsService
  ) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.permissionsService.isUser().pipe(allowed => {
      if (!allowed) {
        this.router.navigate(['/not-allowed']);
      } 
      return allowed;
    });
  }
}