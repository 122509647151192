import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { chunk } from 'lodash';
import { Product } from 'src/app/models/product';
import { ProductsService } from 'src/app/services/products.service';

@Component({
  selector: 'app-products-page',
  templateUrl: './products-page.component.html',
  styleUrls: ['./products-page.component.scss']
})
export class ProductsPageComponent implements OnInit {
  products: Product[] = [];

  constructor(
    private readonly router: Router,
    private readonly productsService: ProductsService
  ) { }

  ngOnInit(): void {
    this.productsService.getProducts().subscribe(products => this.products = products)
  }

  newProduct(): void {
    this.router.navigate(['products', 'new']);
  }

  dividedProducts(rowSize: number): Product[][] {
    return chunk(this.sortProducts(this.products), rowSize);
  }

  cardSelected(product: Product) {
    this.router.navigate(['products', product.productId])
  }

  sortProducts(products: Product[]): Product[] {
    return products.sort((p1, p2) => {
      if (p1.name === p2.name) {
        if (p1.productId === p2.productId) {
          return 0;
        } else {
          return p1.productId > p2.productId ? 1 : -1;
        }
      } else {
        return p1.name > p2.name ? 1 : -1
      }     
    });
  }

}
