import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export enum ToastType {
  SUCCESS,
  FAILURE,
  STANDARD
}

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  toasts$: BehaviorSubject<{ message: string, type: ToastType }> = new BehaviorSubject<{ message: string, type: ToastType }>({ message: '', type: ToastType.STANDARD });

  constructor() { }

  getToastFeed(): Observable<{ message: string, type: ToastType }> {
    return this.toasts$;
  }

  notify(message: string, type: ToastType) {
    this.toasts$.next({ message, type });
  }
}
