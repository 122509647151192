<div class="row">
    <div class="col image-container">
        <img [src]="product.image" class="img-fluid rounded-start" [alt]="'Picture of ' + product.name">
    </div>
    <div class="col">
        <div class="row">
            <div class="col text-end fw-bold product-label px-1 text-dark">PRODUCT ID:</div>
            <div class="col text-start px-0">{{product.productId}}</div>
        </div>
        <!-- <div class="row">
            <div class="col text-end fw-bold product-label px-1">RELEASE:</div>
            <div class="col text-start px-0">not here yet</div>
        </div> -->
        <div class="row">
            <div class="col text-end fw-bold product-label px-1 text-dark">OS:</div>
            <div class="col text-start px-0">{{product.operatingSystem}}</div>
        </div>
        <div class="row">
            <div class="col text-end fw-bold product-label px-1 text-dark">HARDWARE:</div>
            <div class="col text-start px-0">{{product.hardware}}</div>
        </div>
        <!-- <div class="row">
            <div class="col text-end fw-bold product-label px-1 text-dark">DEVICES:</div>
            <div class="col text-start px-0">{{product.totalDeviceCount}}</div>
        </div> -->
        <div class="row">
            <div class="col text-end fw-bold product-label px-1 text-dark">DESCRIPTION:</div>
            <div class="col text-start px-0">{{product.description}}</div>
        </div>
        <!-- <div class="row">
            <div class="col-1"></div>
            <div class="col text-start">{{product.description}}</div>
        </div> -->
    </div>
</div>