<div class="card mb-3 border-secondary" >
    <div class="card-header text-center text-uppercase border-bottom-0 bg-secondary text-light">{{product.name}}</div>
    <div class="row g-0">
        <div class="col-md-4">
            <img [src]="product.image" class="img-fluid" [alt]="'Picture of ' + product.name">
        </div>
        <div class="col-md-8">
            <div class="card-body">
                <div class="row">
                    <div class="col text-end fw-bold product-label px-1">PRODUCT ID:</div>
                    <div class="col text-start px-0">{{product.productId}}</div>
                </div>
                <div class="row">
                    <div class="col text-end fw-bold product-label px-1">OS:</div>
                    <div class="col text-start px-0">{{product.operatingSystem}}</div>
                </div>
                <div class="row">
                    <div class="col text-end fw-bold product-label px-1">HARDWARE:</div>
                    <div class="col text-start px-0">{{product.hardware}}</div>
                </div>
            </div>
        </div>
    </div>
</div>