import { Device } from "./device";

export class ReleaseChannel {
    // type: string = 'release';
    // title: string = 'Release';
    name: string = '';
    ordinal: number = 0;

    status: number = 0;
    detail: string = '';
    instance: string = '';

    devices: Device[] = [];
    
    count: number = 0;

    constructor(init?: any) {
        Object.assign(this, init);
    }
}