import { Component, Input, OnInit } from '@angular/core';
import { Product } from 'src/app/models/product';
import { ReleaseChannel } from 'src/app/models/release-channel';
import { ReleaseVersion } from 'src/app/models/release-version';

@Component({
  selector: 'app-release-summary',
  templateUrl: './release-summary.component.html',
  styleUrls: ['./release-summary.component.scss']
})
export class ReleaseSummaryComponent implements OnInit {
  @Input() product: Product = new Product();
  @Input() channels: ReleaseChannel[] = []

  constructor() { }

  ngOnInit(): void {
  }

  get sortedChannels(): ReleaseChannel[] {
    return this.channels.sort((a, b) => b.ordinal - a.ordinal);
  }

  get unassignedVersion(): string {
    return this.product.releases.filter(release => !release.channel).sort(ReleaseVersion.compareByVersion).pop()?.version || 'N/A';
  }

}
