<nav class="navbar navbar-expand-md navbar-light bg-light border-5 border-black border-top">
    <div class="container-fluid">
        <a class="navbar-brand ms-3" href="#">
            <img src="assets/delta-logo-red.svg" alt="Delta Logo">
        </a>
        <div class="navbar-collapse collapse d-sm-inline-flex justify-content-between">
            <ul class="navbar-nav flex-grow-1">
            </ul>
            <ul class="navbar-nav">
                <li class="nav-item dropdown" *ngIf="hideLogin">
                    <a class="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <i class="fa fa-user-circle-o me-1" aria-hidden="true"></i>{{userAccount?.name}}
                    </a>
                    <ul class="dropdown-menu" aria-labelledby="userDropdown">
                        <li><a class="dropdown-item" href="#" (click)="logout()">Sign out</a></li>
                    </ul>
                </li>

                <li class="nav-item" *ngIf="hideLogin === false">
                    <a class="nav-link" href="#" (click)="login()">Sign in</a>
                </li>
            </ul>
        </div>
    </div>
</nav>