import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Device } from 'src/app/models/device';
import { ReleaseChannel } from 'src/app/models/release-channel';

@Component({
  selector: 'app-device-list',
  templateUrl: './device-list.component.html',
  styleUrls: ['./device-list.component.scss']
})
export class DeviceListComponent implements OnInit, OnChanges {
  @Input() devices: Device[] = [];
  @Input() availableChannels: ReleaseChannel[] = [];
  @Output() rechannel = new EventEmitter<{newChannel: string, devices: Device[]}>();
  @Output() filterChanged = new EventEmitter<string>();

  selectableDevices: { checked: boolean, device: Device }[] = [];

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    if (!this.selectableDevices || this.selectableDevices.length <= 0) {
      this.selectableDevices = this.devices.map(device => ({ checked: false, device}));
    } else {
      this.selectableDevices = this.devices.map(device => ({ checked: this.selectableDevices.find(existingDevice => existingDevice.device.deviceId === device.deviceId)?.checked || false, device}));
    }
  }

  moveToChannel(channel: ReleaseChannel) {
    const selectedDevices = this.selectableDevices.filter(sd => sd.checked).map(sd => sd.device);
    this.rechannel.emit({newChannel: channel.name, devices: selectedDevices})
  }

  allChecked(checked: any) {
    this.selectableDevices.forEach(sd => sd.checked = checked.target.checked)
  }

  filter(value: string) {
    this.selectableDevices = [];
    this.filterChanged.emit(value);
  }

  anySelected(): boolean {
    return this.selectableDevices.map(d => d.checked).reduce((a, b) => a || b, false);
  }

}
