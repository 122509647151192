<div class="container">
    <h1>{{product.name}}</h1>
    <div class="row">
        <div class="col-8">
            <app-product-details [product]="product"></app-product-details>
        </div>
        <div class="col-4">
            <app-release-summary [product]="product" [channels]="channels"></app-release-summary>
        </div>
    </div>
</div>
<div>
    <div class="pt-5">
        <app-release-distribution-line [product]="product" [channels]="channels" [readOnly]="isBasicUser"></app-release-distribution-line>
    </div>
</div>