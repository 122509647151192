import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Device } from 'src/app/models/device';
import { ReleaseChannel } from 'src/app/models/release-channel';
import { PermissionsService } from 'src/app/services/permissions.service';
import { ProductsService } from 'src/app/services/products.service';
import { ToastService, ToastType } from 'src/app/services/toast.service';

@Component({
  selector: 'app-channel-page',
  templateUrl: './channel-page.component.html',
  styleUrls: ['./channel-page.component.scss']
})
export class ChannelPageComponent implements OnInit {
  availableChannels: ReleaseChannel[] = [];
  productId: string = '';
  channel: string = '';
  devices: Device[] = [];
  nextToken: string | null = null;
  currentFilter: string = '';

  constructor(
    private readonly productsService: ProductsService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly toastService: ToastService,
    private readonly permissionsService: PermissionsService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((params: any) => {
      this.productId = params.productId;
      this.channel = params.channel;
      this.productsService.getReleaseDevices(this.productId, this.channel, 10, this.currentFilter).subscribe(devices => {
        this.devices = devices.entities;
        this.nextToken = devices.continuationToken;
      });
      this.productsService.getReleaseChannels(this.productId).subscribe(channels => this.availableChannels = channels.filter(channel => channel.name !== this.channel));
    })
    this.permissionsService.isElevated().subscribe(isElevated => {
      if (!isElevated) {
        this.router.navigate(['not-allowed']);
      }
    });
  }

  rechannel(rechannelItems: { newChannel: string, devices: Device[] }): void {
    this.productsService.rechannelDevices(this.productId, rechannelItems.newChannel, rechannelItems.devices).subscribe(devices => {
      this.toastService.notify(`${rechannelItems.devices.length} device(s) have been scheduled for rechanneling to the ${rechannelItems.newChannel} channel.`, ToastType.SUCCESS);
      this.router.navigate(['products', this.productId]);
    },
      error => {
        this.toastService.notify('Rechanneling of devices has failed, please try again.', ToastType.FAILURE);
      }
    )
  }

  loadMore(): void {
    if (!!this.nextToken) {
      this.productsService.getMoreReleaseDevices(this.productId, this.channel, this.nextToken, 10, this.currentFilter).subscribe(moreDevices => {
        this.devices = [...this.devices, ...moreDevices.entities];
        this.nextToken = moreDevices.continuationToken;
      });
      this.nextToken = null;
    }
  }

  loadWithFilter(filter: string) {
    this.currentFilter = filter;
    this.productsService.getReleaseDevices(this.productId, this.channel, 10, this.currentFilter).subscribe(devices => {
      this.devices = devices.entities;
      this.nextToken = devices.continuationToken;
    });
  }

}
