<div class="container-lg">
    <div class="row">
        <div class="col">
            <h1 class="text-uppercase">Products</h1>
        </div>
        <!-- <div class="col">
            <button class="btn btn-primary float-end" (click)="newProduct()">Add New Product</button>
        </div> -->
    </div>
    <div class="d-xl-none">
        <div *ngFor="let products of dividedProducts(2)" class="row">
            <div *ngFor="let product of products" class="col-md-6" (click)="cardSelected(product)">
                <app-product-card class="clickable" [product]="product"></app-product-card>
            </div>
        </div>
    </div>
<div class="d-none d-xl-block">
    <div *ngFor="let products of dividedProducts(3)" class="row">
        <div *ngFor="let product of products" class="col-md-6 col-xl-4" (click)="cardSelected(product)">
            <app-product-card class="clickable" [product]="product"></app-product-card>
        </div>
    </div>
</div>
</div>