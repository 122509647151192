import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { MsalModule, MsalService, MsalGuard, MsalInterceptor, MsalBroadcastService, MsalRedirectComponent, MsalInterceptorConfiguration, MsalGuardConfiguration, MSAL_INSTANCE, MSAL_GUARD_CONFIG, MSAL_INTERCEPTOR_CONFIG } from "@azure/msal-angular";
import { PublicClientApplication, InteractionType, BrowserCacheLocation, IPublicClientApplication } from "@azure/msal-browser";

import { environment } from 'src/environments/environment';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ProductCardComponent } from './presentational-components/product-card/product-card.component';
import { ProductsPageComponent } from './application-components/products-page/products-page.component';
import { ProductViewPageComponent } from './application-components/product-view-page/product-view-page.component';
import { NotFoundPageComponent } from './application-components/not-found-page/not-found-page.component';
import { HeaderComponent } from './presentational-components/header/header.component';
import { DragDropDirective } from './directives/drag-drop.directive';
import { ProductDetailsComponent } from './presentational-components/product-details/product-details.component';
import { ReleaseSummaryComponent } from './presentational-components/release-summary/release-summary.component';
import { ReleaseDistributionLineComponent } from './presentational-components/release-distribution-line/release-distribution-line.component';
import { ChannelPageComponent } from './application-components/channel-page/channel-page.component';
import { AllChannelsPageComponent } from './application-components/all-channels-page/all-channels-page.component';
import { ReleasesPageComponent } from './application-components/releases-page/releases-page.component';
import { BreadcrumbsComponent } from './application-components/breadcrumbs/breadcrumbs.component';
import { DeviceListComponent } from './presentational-components/device-list/device-list.component';
import { ReleaseAssignmentComponent } from './presentational-components/release-assignment/release-assignment.component';
import { FooterComponent } from './presentational-components/footer/footer.component';
import { PaginatorComponent } from './presentational-components/paginator/paginator.component';
import { HomePageComponent } from './application-components/home-page/home-page.component';
import { NotAllowedPageComponent } from './application-components/not-allowed-page/not-allowed-page.component';
import { LogoutFinishedComponent } from './application-components/logout-finished/logout-finished.component';
import { SignoutComponent } from './application-components/signout/signout.component';
import { ToastComponent } from './application-components/toast/toast.component';
import { FromToChannelComponent } from './presentational-components/from-to-channel/from-to-channel.component';

export function MsalConfigFactory() : IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.aadClientId,
      authority: environment.aadAuthority,
      redirectUri: window.location.origin,
    },
    cache: {
        cacheLocation : BrowserCacheLocation.LocalStorage,
        storeAuthStateInCookie: true,
    },
    system: {
        loggerOptions: {
            loggerCallback: () => {},
            piiLoggingEnabled: false
        }
    }
  });
}

export function MsalGuardConfigFactory(): MsalGuardConfiguration {
  return { 
    interactionType: InteractionType.Redirect
  };
}

export function MsalInterceptorConfigFactory() : MsalInterceptorConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap: new Map([
      // [environment.apiUrl.concat("/*"), ['access_as_user']]
      [environment.apiUrl.concat("/*"), [`api://${environment.apiClientId}/access_as_user`]]
    ])
  };
}

@NgModule({
  declarations: [
    AppComponent,
    ProductCardComponent,
    ProductsPageComponent,
    ProductViewPageComponent,
    NotFoundPageComponent,
    HeaderComponent,
    DragDropDirective,
    ProductDetailsComponent,
    ReleaseSummaryComponent,
    ReleaseDistributionLineComponent,
    ChannelPageComponent,
    AllChannelsPageComponent,
    ReleasesPageComponent,
    BreadcrumbsComponent,
    DeviceListComponent,
    ReleaseAssignmentComponent,
    FooterComponent,
    PaginatorComponent,
    HomePageComponent,
    NotAllowedPageComponent,
    LogoutFinishedComponent,
    SignoutComponent,
    ToastComponent,
    FromToChannelComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    MsalModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MsalConfigFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MsalGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MsalInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ],
  bootstrap: [AppComponent,
     MsalRedirectComponent
    ]
})
export class AppModule { }
