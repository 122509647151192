<div class="bg-light py-3">
    <div>
        <h2 class="text-uppercase text-center mb-0">{{product.name}} channels</h2>
        <p class="text-center">View and modify channel settings</p>
    </div>
    <div class="row mx-0 px-0">
        <div class="col channel text-center px-0" *ngFor="let channel of sortedChannels">
            <div class="row number-line">
                <div class="col left-line py-3"></div>
                <div class="col right-line"></div>
            </div>
            <button class="btn pt-0 border-0" [class.disabled]="readOnly" [routerLink]="channel.name">
                <div class="text-center text-uppercase rounded-1 py-1 px-2 btn-primary">
                    <div>{{channel.name}}</div>
                </div>
                <div>{{product.getVersionForChannel(channel.name)?.version || 'N/A'}}</div>
                <div class="text-center fw-bold">{{channel.count}}</div>
            </button>
        </div>
        <div class="col channel text-center">
            <div class="row number-line">
                <div class="col left-line py-3"></div>
                <div class="col right-line"></div>
            </div>
            <button class="btn pt-0 border-0" [class.disabled]="readOnly" routerLink="all">
                <div class="text-center text-uppercase rounded-1 py-1 px-2 btn-primary">All</div>
                <div class="text-center fw-bold">{{allProductsCount()}}</div>
            </button>
        </div>
    </div>
</div>