import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { map, mergeAll } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Device, DevicesFragment } from '../models/device';
import { Product } from '../models/product';
import { ReleaseChannel } from '../models/release-channel';
import { ReleaseVersion } from '../models/release-version';



@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  constructor(
    private readonly httpClient: HttpClient
  ) { }

  getProductsWithCount(): Observable<Product[]> {
    return this.httpClient.get<Product[]>(`${environment.apiUrl}/v1/products`).pipe(
      map(products => products.map(product => {
        return this.httpClient.get<{ count: number }>(`${environment.apiUrl}/v1/products/${product.productId}/devices/count`).pipe(
          map(devices => new Product({
            ...product,
            count: devices.count
          }))
        )
      })),
      map(obs => combineLatest(obs)),
      mergeAll()
    );
  }

  getProducts(): Observable<Product[]> {
    return this.httpClient.get<Product[]>(`${environment.apiUrl}/v1/products`).pipe(
      map(products => products.map(product => new Product(product)))
    );
  }

  getProductById(id: string): Observable<Product> {
    return this.httpClient.get<Product>(`${environment.apiUrl}/v1/products/${id}`).pipe(
      map(product => new Product(product))
    );
  }

  getReleases(id: string): Observable<ReleaseVersion[]> {
    return this.httpClient.get<ReleaseVersion[]>(`${environment.apiUrl}/v1/products/${id}/releases`).pipe(
      map(releases => releases.map(release => new ReleaseVersion(release)))
    )
  }

  getReleasesLatestOnly(id: string): Observable<ReleaseVersion[]> {
    return this.getReleaseChannels(id).pipe(
      map(channels => channels.map(channel => {
        return this.httpClient.get<ReleaseVersion>(`${environment.apiUrl}/v1/products/${id}/releases/latest?channelName=${channel.name}`).pipe(
          map(release => new ReleaseVersion({ ...release, channel: channel.name }))
        )
      })),
      map(obs => combineLatest(obs)),
      mergeAll()
    )
  }

  getReleaseLatestUnassigned(productId: string): Observable<ReleaseVersion> {
    return this.httpClient.get<ReleaseVersion>(`${environment.apiUrl}/v1/products/${productId}/releases/latest`).pipe(
      map(release => new ReleaseVersion(release))
    )
  }

  getReleaseChannels(productId: string): Observable<ReleaseChannel[]> {
    return this.httpClient.get<ReleaseChannel[]>(`${environment.apiUrl}/v1/products/${productId}/channels`).pipe(
      map(channels => channels.map(channel => new ReleaseChannel(channel)))
    );
  }

  getReleaseChannelsWithCount(productId: string): Observable<ReleaseChannel[]> {
    return this.getReleaseChannels(productId).pipe(
      map(channels => channels.map(channel => {
        return this.httpClient.get<{ count: number }>(`${environment.apiUrl}/v1/products/${productId}/devices/count?channelName=${channel.name}`).pipe(
          map(devices => new ReleaseChannel({
            ...channel,
            count: devices.count
          }))
        )
      })),
      map(obs => combineLatest(obs)),
      mergeAll()
    )
  }

  getCompleteProduct(id: string): Observable<Product> {
    return combineLatest([this.getProductById(id), this.getReleasesLatestOnly(id), this.getReleaseLatestUnassigned(id)]).pipe(
      map(([product, releases, latestUnassigned]) => {
        product.releases = [...releases, latestUnassigned];
        return product;
      })
    )
  }

  createProduct(product: Product): Observable<Product> {
    return of(new Product({
      ...product,
      id: Math.random().toString()
    }));
  }

  getReleaseDevices(productId: string, channel: string, pageSize: number = 10, filter?: string): Observable<DevicesFragment> {
    const filterTerm = !!filter ? `&searchTerm=${filter}` : '';
    return this.httpClient.get<DevicesFragment>(`${environment.apiUrl}/v1/products/${productId}/channels/${channel}/devices?pageSize=${pageSize}${filterTerm}`).pipe(
      map(devices => ({
        entities: devices.entities.map(d => new Device(d)),
        continuationToken: devices.continuationToken
      })),
    )
  }

  getMoreReleaseDevices(productId: string, channel: string, continuationToken: string, pageSize: number = 10, filter?: string): Observable<DevicesFragment> {
    const filterTerm = !!filter ? `&searchTerm=${filter}` : '';
    return this.httpClient.get<DevicesFragment>(`${environment.apiUrl}/v1/products/${productId}/channels/${channel}/devices?continuationToken=${continuationToken}&pageSize=${pageSize}${filterTerm}`).pipe(
      map(devices => ({
        entities: devices.entities.map(d => new Device(d)),
        continuationToken: devices.continuationToken
      })),
    )
  }

  publishRelease(productId: string, channel: string, version: string): Observable<any> {
    return this.httpClient.patch(`${environment.apiUrl}/v1/products/${productId}/releases/${version}`, {
      channelName: channel
    });
  }

  rechannelDevices(productId: string, newChannel: string, devices: Device[]): Observable<any> {
    return this.httpClient.post<any>(`${environment.apiUrl}/v1/products/${productId}/devices/move`, {
      devices: devices.map(device => ({
        accountId: device.accountId,
        deviceId: device.deviceId
      })),
      targetChannelName: newChannel
    });
  }

  rechannelAllDevicesOnChannel(productId: string, oldChannel: string, newChannel: string): Observable<any> {
    return this.httpClient.post<any>(`${environment.apiUrl}/v1/products/${productId}/channel/${oldChannel}/devices/move`, {
      targetChannelName: newChannel
    });
  }

  downloadRelease(product: string, releaseVersion: string): Observable<boolean> {
    return this.httpClient.get(`${environment.apiUrl}/v1/products/${product}/releases/${releaseVersion}/download`, {
      responseType: 'arraybuffer'
    })
      .pipe(
        map(file => {
          const blob = new Blob([file as any], { type: 'application/zip' });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${product}-${releaseVersion}.zip`);
          document.body.appendChild(link);
          link.click();
          return true;
        })
      )
  }
}
